import React from 'react';
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';
import Logo from '../assets/mmsili-online-white 1.png';
import Apple from '../assets/IOS.png';
import Android from '../assets/Android.png';
import SubscribeInput from '../components/SubscribeInput'

const Footer = () => {
  return (
    <>
      {/* Desktop footer */}
      <div className='max-w-auto  mx-auto py-16 px-4 lg:grid-cols-3 gap-8  bg-black text-white  hidden md:block' >
        {/* Subscription email input */}
        {/* <div className="justify-center mb-8  align-middle">
          <div className='relative mb-8 align-middle'>
            <input type="text" className="w-full sm:w-[100%] h-12 pl-6 sm:pl-14 bg-white rounded-full border-2 border-cyan-600 text-cyan-800 placeholder-cyan-800 placeholder-opacity-80 placeholder-[40px] font-medium leading-loose" placeholder="Enter your email, to subscribe" />
            <div className="absolute top-0 right-0 h-full w-12 sm:w-16 flex items-center justify-center bg-black">
              <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.5 15.5l5.5 5.5"></path>
                <circle cx="11" cy="11" r="8"></circle>
              </svg>
            </div>
          </div>
        </div> */}
      <center>  <SubscribeInput/></center>

        <div className='lg:col-span-2 flex justify-between mt-6'>
          <div>
            <img src={Logo} alt='Company Logo' className='h-10 mr-4' />
            <p className='py-4'>
              {/* Add Apple and iOS icons here */}
              <span>
                <img src={Apple} alt="Apple Icon" className="w-15 h-10 mx-2 inline-block" />
              </span>
              <span>
                <img src={Android} alt="iOS Icon" className="w-15 h-10 mx-2 inline-block" />
              </span>
            </p>
            <div className='flex md:w-[75%] my-6'>
              <FaFacebookSquare size={30} />
              <FaInstagram size={30} />
              <FaTwitterSquare size={30} />
              {/* <FaGithubSquare size={30} />
              <FaDribbbleSquare size={30} /> */}
            </div>
          </div>
          <div>
            <h6 className='font-medium text-gray-400'>Home</h6>
          </div>
          <div>
            <h6 className='font-medium text-gray-400'>Services</h6>
            <ul>
              <li className='py-2 text-sm'>ICT</li>
              <li className='py-2 text-sm'>Media & Graphics</li>
              <li className='py-2 text-sm'>Mechanical & Electricals </li>
              <li className='py-2 text-sm'>Lifestyle</li>
              <li className='py-2 text-sm'>Other services</li>
            </ul>
          </div>
          <div>
            <h6 className='font-medium text-gray-400'>Privacy Policy</h6>
          </div>
          <div>
            <h6 className='font-medium text-gray-400'>About us</h6>
          </div>
          <div>
            <h6 className='font-medium text-gray-400'>Contacts</h6>
            <ul>
              <li className='py-2 text-sm'>+265 999 776 931 / +265 888 776 931</li>
              <li className='py-2 text-sm'>info@mmisilionline.com</li>
              <li className='py-2 text-sm'>Area 47, Sector 4</li>
            </ul>
          </div>
        </div>
        {/* Copyright */}
        <div className="flex justify-center mt-6">
          <p className="text-sm text-gray-400">
            © {new Date().getFullYear()} - Static Computers Inc All rights reserved  .
          </p>
        </div>
      </div>

      {/* Mobile footer */}
      <div className='mx-auto py-16 px-4 grid gap-8  bg-black text-white md:hidden'>
        <div className='relative mb-8'>
          <input type="text" className="w-full sm:w-[100%] h-12 pl-6 sm:pl-14 bg-white rounded-full border-2 border-cyan-600 text-cyan-800 placeholder-cyan-800 placeholder-opacity-80 placeholder-[40px] font-medium leading-loose" placeholder="Enter your email, to subscribe" />
          <div className="absolute top-0 right-0 h-full w-12 sm:w-16 flex items-center justify-center bg-black">
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.5 15.5l5.5 5.5"></path>
              <circle cx="11" cy="11" r="8"></circle>
            </svg>
          </div>
        </div>


        <div className='max-w-screen-lg mx-auto align-middle'>
          <img src={Logo} alt='Company Logo' className='h-10 mr-4' />
          <p className='py-4 flex'>
            {/* Add Apple and iOS icons here */}
            <span className="flex flex-grow justify-center">
              <img src={Apple} alt="Apple Icon" className="w-15 h-10 mx-8" />
            </span>
            <span className="flex flex-grow justify-center">
              <img src={Android} alt="iOS Icon" className="w-15 h-10   mx-8" />
            </span>
          </p>
          <div className='flex justify-center md:w-[75%] my-6'>
            <FaFacebookSquare size={30} />
            <FaInstagram size={30} />
            <FaTwitterSquare size={30} />
            {/* <FaGithubSquare size={30} />
            <FaDribbbleSquare size={30} /> */}
          </div>
        </div>
        <div className='md:flex md:justify-between md:max-w-screen-lg mx-auto mt-6'>
          <div>
            <h6 className='font-medium text-gray-400'>Home</h6>
          </div>
          <div>
            <h6 className='font-medium text-gray-400'>Services</h6>
            <ul>
              <li className='py-2 text-sm'>ICT</li>
              <li className='py-2 text-sm'>Media & Graphics</li>
              <li className='py-2 text-sm'>Mechanical & Electricals </li>
              <li className='py-2 text-sm'>Lifestyle</li>
              <li className='py-2 text-sm'>Other services</li>
            </ul>
          </div>
          <div>
            <h6 className='font-medium text-gray-400'>Privacy Policy</h6>
          </div>
          <div>
            <h6 className='font-medium text-gray-400'>About us</h6>
          </div>
          <div>
            <h6 className='font-medium text-gray-400'>Contacts</h6>
            <ul>
              <li className='py-2 text-sm'>+265 999 776 931 / +265 888 776 931</li>
              <li className='py-2 text-sm'>info@mmisilionline.com</li>
              <li className='py-2 text-sm'>Area 47, Sector 4</li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="flex justify-center mt-6">
          <p className="text-sm text-gray-400">
            © {new Date().getFullYear()} - Static Computers Inc. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;

