import React from 'react';
import Typed from 'react-typed';
import Card1 from '../assets/c1.png';
import Card2 from '../assets/c3.png';
import Card3 from '../assets/c5.png';
import Card4 from '../assets/c4.png';

const Hero = () => {
  return (

    <div className='text-white py-32  m-4'>

      <div className='max-w-[1000px] mt-[-56px] w-full mx-auto text-center flex flex-col justify-center'>


      <div class="relative mb-4">
        <input type="text" class="w-full sm:w-[100%] h-12 pl-6 sm:pl-14 bg-white rounded-full border-2 border-cyan-600 text-cyan-800 placeholder-cyan-800 placeholder-opacity-80 placeholder-[40px] font-medium leading-loose" placeholder="What problem do you have or service you are looking for today?" />
        <div class="absolute top-0 right-0 h-full w-12 sm:w-16 flex items-center justify-center bg-cyan-600 rounded-r-full">
          <svg class="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.5 15.5l5.5 5.5"></path>
            <circle cx="11" cy="11" r="8"></circle>
          </svg>
        </div>
      </div>

        <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'></h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'></p>
          <Typed
            className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={[]}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
      </div>
      <div className='max-w-[1200px] mt-[-32px] w-full mx-auto text-center flex flex-col justify-center'>
        {/* Grid of four rows */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-2">
      
     


            {/* Row 2 */}
          <div className="group rounded-lg overflow-hidden hover:bg-[#3287c1]">
            <div className="flex items-center justify-center">
              <img src={Card1} alt="Image 2" className="w-50 h-50 object-cover" />
            </div>
            <div className="h-1 bg-cyan-600 my-2"></div>
            <div className="p-4">
              <h3 className="text-xl font-semibold text-center mb-2 text-black group-hover:text-white">Call In</h3>
              <p className="text-gray-600 group-hover:text-white">
                Do you want it done now? Call 6349 now and talk to an expert waiting to solve your problem.
              </p>
            </div>
          </div>



            {/* Row 3 */}
          <div className="group rounded-lg overflow-hidden hover:bg-[#3287c1]">
            <div className="flex items-center justify-center">
              <img src={Card3} alt="Image 2" className="w-50 h-50 object-cover" />
            </div>
            <div className="h-1 bg-cyan-600 my-2"></div>
            <div className="p-4">
              <h3 className="text-xl font-semibold text-center mb-2 text-black group-hover:text-white">Always Available</h3>
              <p className="text-gray-600 group-hover:text-white">
                Is your guy busy, engaged, far,  unavailable? Call or browse our available experts now
              </p>
            </div>
          </div>


            {/* Row 4 */}
          <div className="group rounded-lg overflow-hidden hover:bg-[#3287c1]">
            <div className="flex items-center justify-center">
              <img src={Card4} alt="Image 2" className="w-50 h-50 object-cover" />
            </div>
            <div className="h-1 bg-cyan-600 my-2"></div>
            <div className="p-4">
              <h3 className="text-xl font-semibold text-center mb-2 text-black group-hover:text-white">Close to you</h3>
              <p className="text-gray-600 group-hover:text-white">
                The best guy is close to you, you don't know them, we know them. Search here and you will know them
              </p>
            </div>
          </div>


           {/* Row 1 */}
          <div className="group rounded-lg overflow-hidden hover:bg-[#3287c1]">
            <div className="flex items-center justify-center">
              <img src={Card2} alt="Image 2" className="w-50 h-50 object-cover" />
            </div>
            <div className="h-1 bg-cyan-600 my-2"></div>

            <div className="p-4">
              <h3 className="text-xl font-semibold text-center mb-2 text-black group-hover:text-white">Find a Job</h3>
              <p className="text-gray-600 group-hover:text-white">
              Do you have the skills, are you broke and jobless? Register and tell us what you know and  will find job for you.
              </p>
            </div>

          </div>

        </div>
      </div>

    </div>

  );
};

export default Hero;
