import React from 'react';
import S1 from '../assets/s1.png';
import S2 from '../assets/s2.png';
import S3 from '../assets/s3.png';
import S4 from '../assets/s4.png';

const ServicesComponent = () => {
  // Sample data for services (you can replace this with your actual data)
  const servicesData = [
    {
      id: 1,
      image: S1,
      text: 'ICT & Tech',
      description: 'Phone repair Software Development Support and IT',
    },
    {
      id: 2,
      image: S2,
      text: 'Media & Graphics',
      description: 'Video Editing ADS and Commercials Jingles and Animation Logo Design and Animation',
    },
    {
      id: 3,
      image: S3,
      text: 'Mechanical & Electrical',
      description: 'Electrical Help Mechanical Help Electrical Installation ',
    },
    {
      id: 4,
      image: S4,
      text: 'Carpentry & Plumbing ',
      description: 'Minor Plumbing Furniture Assembling Gardening.',
    },
   
    // Add more services if needed 
  ];
  return (
<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 overflow-x-scroll">
  {servicesData.map((service) => (
    <div key={service.id} className="bg-white shadow rounded-md p-4 hover:bg-gray-100">
      <img
        src={service.image}
        alt={service.text}
        className="w-full h-48 object-cover mb-4 rounded-md"
      />
      <p className="text-xl">{service.text}</p>
      <p className="text-left">{service.description}</p>
    </div>
  ))}
</div>

  );
};
export default ServicesComponent;

