import React from 'react';

import OS1 from '../assets/os1.png';
import OS2 from '../assets/os2.png';
import OS3 from '../assets/os3.png';

const OtherServices = () => {
  const servicesData = [
    {
      id: 1,
      image: OS2,
      text: 'Individual',
      description: 'Waiting for points',
    },
    {
      id: 2,
      image: OS1,
      text: 'Family and Groups',
      description: 'Waiting for points',
    },
    {
      id: 3,
      image: OS3,
      text: 'Organizations and Corporates',
      description: 'Waiting for points',
    },
    // Add more services if needed
  ];

  return (
    <div className='w-full bg-[#3287c1] py-16 px-4 '>
      <center>
           <p className="text-center text-white mb-4"> More ways to Discover</p>
      </center>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 overflow-x-scroll">
        {servicesData.map((service) => (
          <div key={service.id} className="bg-white shadow rounded-md p-4 hover:bg-gray-100">
            <img
              src={service.image}
              alt={service.text}
              className="w-full h-40 object-contain mb-4 rounded-md"
            />
            <p className="text-xl">{service.text}</p>
            <p className="text-left">{service.description}</p>
            <p className="text-left">
               <button className="px-5 py-2 bg-[#3287c1] text-white rounded-3xl">Learn more</button>
            </p>
           

          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherServices;
