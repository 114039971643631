import React from 'react';
import Clock from '../assets/redclock.png';


const WhyContainer = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-[#3287c1] text-white p-4 rounded-xl m-8 ">
      {/* Left column */}
      <div className="flex flex-col justify-center items-left">
        <h2 className="text-2xl font-bold">WHY CHOOSE MMISILI ONLINE?</h2>
        <ul className="list-disc pl-6">
          <li>No registration costs</li>
          <li>Available 27/4 2</li>
          <li>Low Affordable price rates 3</li>
          <li>Safe and secure</li>
          <li>We get it done when you need it</li>
          <li>AExceptional services and quality results</li>
    
        </ul>
      </div>

      {/* Right column */}
      <div className="bg-white p-4 rounded-md flex justify-center items-center">
        <img src={Clock} alt="Image" className="w-full max-w-[400px] h-auto" />
      </div>
    </div>
  );
};

export default WhyContainer;
