import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Logo from '../assets/logo.png';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (

    <>
      <div className='relative'>
        <div className='absolute inset-x-0 bottom-0 h-0.5 bg-gray-300'></div>
        <div className='flex justify-between items-center h-24 max-w-[3340px] mx-auto px-2 text-[#3287c1]'>
          <div className='flex start justify-start'>
            <img src={Logo} alt='Company Logo' className='h-10 mr-4' />
            <ul className='hidden md:flex space-x-16'>
              <li className='p-2 px-8' style={{ whiteSpace: 'nowrap', color: '#3287c1' }}>ICT</li>
              <li className='p-2' style={{ whiteSpace: 'nowrap', color: '#3287c1' }}>Media & Graphics</li>
              <li className='p-2' style={{ whiteSpace: 'nowrap', color: '#3287c1' }}>Mechanical & Electricals</li>
              <li className='p-2' style={{ whiteSpace: 'nowrap', color: '#3287c1' }}>Lifestyle</li>
              <li className='p-2' style={{ whiteSpace: 'nowrap', color: '#3287c1' }}>Other services</li>
              <li className='p-2'>
                <div className="flex space-x-4">
                  <div className="w-44 h-10 px-4 py-2.5 rounded-full shadow border border-cyan-600 justify-start items-center gap-2 inline-flex hover:bg-[#3287c1]  hover:text-white">
                    <div className="text-cyan-600 text-base font-medium leading-tight tracking-wide  hover:text-white">Become a tasker</div>
                  </div>
                  <button className="px-4 py-2 bg-[#3287c1] text-white rounded-3xl  hover:bg-white hover:text-[#3287c1] ">Sign Up</button>
                </div>
              </li>
            </ul>
          </div>


          <div onClick={handleNav} className='block md:hidden'>
            {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
          </div>

          {/* Mobile menu */}
          <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-white ease-in-out duration-500 shadow-md z-10' : 'ease-in-out duration-500 fixed left-[-100%] z-0'}>
            <li className='p-4 overflow-hidden'>ICT</li>
            <li className='p-4 overflow-hidden'>Media & Graphics</li>
            <li className='p-4 overflow-hidden'>Mechanical & Electricals</li>
            <li className='p-4 overflow-hidden'>Lifestyle</li>
            <li className='p-4 overflow-hidden'>Other services</li>
            <li>
              &nbsp;&nbsp;&nbsp;<div className="w-44 h-10 px-4 py-2 rounded-full shadow border border-cyan-600 justify-start items-center gap-2 inline-flex hover:bg-[#3287c1]  hover:text-white">
                <div className="text-cyan-600 text-base font-medium leading-tight tracking-wide hover:text-white">Become a tasker</div>
              </div>
            </li>
            <li className='p-4'>
              <button className="px-4 py-2 bg-[#3287c1] text-white rounded-3xl hover:bg-white hover:text-[#3287c1] ">Sign Up</button>
            </li>
          </ul>
        </div>

      </div>

      <div className='justify-center items-center mb-4 mx-16 my-14 hidden md:block'>
        <span className='text-black text-7xl'>You have a problem, we help you fix it</span>
      </div>

      <div className=' mb-1  md:hidden m-3 items-center p-4' >
        <span className='text-black text-3xl'>You have a problem, we help you fix it</span>
      </div>


    </>

  );
};

export default Navbar;
