import React from 'react';
import Analytics from './components/Analytics';
import Cards from './components/Cards';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import JumbotronA from './components/Jumbotron';
import ServicesComponent from './components/Services';
import OtherServices from './components/OtherServices';
import WhyContainer from './components/WhyContainer';
import FindService from './components/FindService';
import DropdownRow from './components/DropdownRow';
import PartnersScrollableGrid from './components/PartnersScrollableGrid';





function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <JumbotronA />
      <div className="container mx-auto mt-8 py-10">
        <h1 className="text-xl  mb-4">Popular Services</h1>
        <ServicesComponent />
      </div>
      <div className="container-fluid mx-auto mt-8 py-16">
        <OtherServices />
       </div>
      <WhyContainer/>
      <FindService/>

      {/* <div className="container-fluid mx-auto mt-8 py-16">
        <h1 className="text-xl text-center mb-4 text-cyan-600">Our Partners</h1>
       < PartnersScrollableGrid/>
       </div> */}

        <div className="container-fluid mx-auto mt-8 py-16">
        <h1 className="text-xl text-center mb-4 text-cyan-600">FAQ</h1>
       <DropdownRow/>
       </div>

       
     
      {/* <Analytics />
      <Newsletter />
      <Cards /> */}
      <Footer />
    </div>
  );
}

export default App;
