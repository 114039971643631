
import React from 'react';

const FindService = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-white m-8 text-black">
      {/* Left column */}
      <div className="flex flex-col justify-start">
        <h2 className="text-3xl font-bold mb-2">Find</h2>
        <p className="text-2xl mb-2">Services Your way</p>
        <p className="text-sm">Browse experts by reviews, star rating, skills, and price </p>
        <button className="bg-black text-white rounded-3xl py-2 px-3 mt-4 self-start">
          Sign up
        </button>
      </div>

      {/* Right column */}
      <div className="flex flex-col justify-start">
        <h2 className="text-3xl font-bold mb-2">Utilize</h2>
        <p className="text-2xl mb-2">Your Skills</p>
        <p className="text-sm">find a job that suits your skills and make money </p>
        <button className="bg-black text-white rounded-3xl py-2 px-3 mt-4 self-start">
          Become a Tasker
        </button>
      </div>
    </div>
  );
};

export default FindService;
