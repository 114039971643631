import React from 'react';
import Hero from '../assets/hero.png';

function JumbotronA() {
    return (
    <>
<div className="relative bg-[#3287c1] max-w-[1500px] text-white rounded-md p-0 mt-10 mx-4 md:mx-16 hidden md:block">
  {/* Set background color to #3287c1 and max-width to 800px */}
  {/* Grid for desktop */}
  <div className="grid grid-cols-3">
    {/* First Column - Take 75% */}
    <div className="col-span-2 p-8">
      <h1 className="text-xl mx-4 md:mx-0 py-8">
        You know two people who can fix your car we know millions, explore ours today
      </h1>
      <div className="w-55 h-4 px-4 text-sm mx-4 md:mx-0 py-5 rounded-full shadow border bg-white justify-start items-center gap-2 inline-flex mt-12">
        <div className="text-cyan-600 text-base font-medium leading-tight tracking-wide">
          Browse services, Jobs
        </div>
      </div>
    </div>
    {/* Second Column - Take 25% */}
    <div className="col-span-1 relative hidden md:block">
      <img
        src={Hero}
        alt="Protruding Image"
        className="absolute bottom-100 right-0 h-80 -mb-32 mr-4 object-cover rounded-md"
        style={{
          position: "absolute",
          top: "-150px",
          height: "400px",
        }}
      />
    </div>
  </div>
</div>

{/* Mobile view */}
<div className="relative bg-[#3287c1] max-w-[400px] h-64 text-white rounded-md p-0 mx-4 md:hidden">
  <div className="flex flex-col justify-center items-center h-full p-4">
    <h1 className="text-sm mt-4">
      You know two people who can fix your car we know millions, explore ours today
    </h1>
    <div className="mt-4">
      <button className="text-[#3287c1] font-medium bg-white border-blue-600 border rounded-full px-4 py-2">
        Browse services, Jobs
      </button>
    </div>
  </div>
</div>




    </>
    );
}

export default JumbotronA;




// function JumbotronA() {
//     return (
//         <div className="relative bg-gray-100  ">
//             {/* First Row */}
//             <div className="grid grid-rows-2">
//                 <div className="row-span-1 p-8">
//                     <h1 className="text-4xl font-bold mb-4">Welcome to My Website</h1>
//                     <p className="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
//                     <button className="px-4 py-2 mt-4 bg-blue-500 text-white rounded hover:bg-blue-600">Click Me</button>
//                 </div>
//                 {/* Second Row */}
//                 <div className="row-span-1 relative">
//                     <img
//                         src={Hero}
//                         alt="Protruding Image"
//                         className="absolute bottom-100 right-0 h-80  -mb-32 mr-4 object-cover"
//                         style={{
//                             position: "absolute",
//                             top: "-260px",
//                             height: "455px",
//                         }}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// }
// export default JumbotronA;
// function JumbotronA() {
//     return (
//         <div className="relative bg-[#3287c1] max-w-[1500px] text-white rounded-md p-0 mx-4 md:mx-16">
//             {/* Set background color to #3287c1 and max-width to 800px */}
//             {/* First Row */}
//             <div className="grid grid-rows-2">
//                 <div className="row-span-1 p-8 flex flex-col items-center md:items-start md:flex-row md:justify-between">

//                     <p className="text-lg text-center md:text-left">
//                         <h1 className="text-4xl mx-16 py8">You know two people who can fix your car<br />
//                         we know millions, explore ours today</h1>
//                     </p>
                    
//                     <button className="px-4 py-2 mt-4 bg-blue-500 text-white rounded hover:bg-blue-600">Click Me</button>
//                 </div>
//                 {/* Second Row - Hide the image on mobile devices */}
//                 <div className="row-span-1 relative hidden md:block">
//                     <img
//                         src={Hero}
//                         alt="Protruding Image"
//                         className="absolute bottom-100 right-0 h-80 -mb-32 mr-4 object-cover rounded-md"
//                         style={{
//                             position: "absolute",
//                             top: "-260px",
//                             height: "455px",
//                         }}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default JumbotronA;