import React, { useState } from 'react';

const Dropdown = ({ title, description }) => {
  const [showDescription, setShowDescription] = useState(false);

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };


  return (
     <div className="relative mb-4">
      <button
        type="button" // Add type="button" to prevent form submission
        className={`${
          showDescription ? 'bg-[#3287c1] text-white' : 'bg-white'
        } rounded-xl border border-gray-300 text-white-600 font-bold px-4 py-10 w-full flex items-center justify-between`}
        onClick={toggleDescription}
      >
        {title}
        <span
          className={`${
            showDescription ? 'transform rotate-180' : ''
          } transition-transform duration-80`}
        >
          ▼
        </span>
      </button>
      <div
        className={`${
          showDescription ? 'block' : 'hidden'
        } bg-white rounded-xl border border-gray-300 px-4 py-10 w-full mt-2`}
      >
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
};

const DropdownRow = () => {
  return (
        <div className="container mx-auto mt-8 px-4 sm:px-8 md:px-16 lg:px-32 xl:px-40">

    <div className="flex flex-col space-y-8">
      <Dropdown
        title="Is Mmisili Online Reliable ?"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      />
      <Dropdown
        title="What is Web programming?"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      />
      <Dropdown
        title="What type of services can I find in Support & IT?"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      />
      <Dropdown
        title="Are they're any free services? "
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
      />
    </div>
    </div>
  );
};

export default DropdownRow;
