import React from 'react';

const SubscribeInput = () => {
  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex items-center  overflow-hidden w-96 rounded-2xl border border-gray-300">
        <input
          type="email"
          placeholder="Enter your email..."
          className="py-2 px-4 rounded-xl focus:outline-none w-full text-black"
        />
        <button
          type="button"
          className="bg-black text-white font-semibold py-2 px-4 rounded-r-lg border-xl border-white hover:bg-gray-800 focus:outline-none"
        >
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default SubscribeInput;
